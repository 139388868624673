import { useRouter } from 'next/router';
import styled from 'styled-components';
import Link from 'next/link';
import i18n from 'src/utils/translate';
import { useSelector } from 'src/store/store';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { textLight } from 'styles-js/mixins/typography';

export default function HomeNav({ isNewsfeed }: { isNewsfeed?: boolean }) {
  const currentUser = useSelector(({ context }) => context?.currentUser);
  const percentageComplete = useSelector(({ currentUserProfile }) => currentUserProfile.basics.percentageComplete || 0);
  if (!currentUser) return null;
  const roundedPercent = Math.floor(percentageComplete);

  return (
    <Nav $isNewsfeed={isNewsfeed} aria-label="my feed navigation" data-testid="home-nav">
      <UserCard $isNewsfeed={isNewsfeed}>
        <img alt={currentUser.username + ' profile image'} src={currentUser.avatarImageUrl} />
        <User>
          <Name>{currentUser.username}</Name>
          {isNewsfeed && roundedPercent < 100 && (
            <ProfileCompletionPercentage>
              {i18n.t(`${roundedPercent}% complete`)}
            </ProfileCompletionPercentage>
          )}
          <Link
            legacyBehavior
            passHref
            as="/profile/edit"
            href={'/user/editProfile'}
          >
            <UpdateProfileLink
              onClick={() => {
                if (!isNewsfeed) return;
                trackFootfallEvent(Event.Clicked, { clickType: 'update-profile', clickMetadata: { section: Section.NewsFeedProfile } });
              }}
            >
              {i18n.t('Update profile')}
            </UpdateProfileLink>
          </Link>
        </User>
      </UserCard>
      <ul>
        {navItems.map(item => <NavItem item={item} key={item.key} />)}
      </ul>
    </Nav>
  );
}

const NavItem = ({ item }: { item: { [key: string]: string } }) => {
  const router = useRouter();
  const stateClass = item.href === router.route ? 'isActive' : '';
  if (item.as) {
    return (
      <Item>
        <Link as={item.as} className={stateClass} href={item.href}>

          <DesktopText>{i18n.t(item.text)}</DesktopText>
          <MobileText>{i18n.t(item.tabText)}</MobileText>

        </Link>
      </Item>
    );
  }
  return (
    <Item>
      <a className={stateClass} href={item.href}>
        <DesktopText>{i18n.t(item.text)}</DesktopText>
        <MobileText>{i18n.t(item.tabText)}</MobileText>
      </a>
    </Item>
  );
};

const navItems = [
  {
    text: 'News Feed',
    tabText: 'News Feed',
    href: '/home/newsfeed',
    as: '/newsfeed', // needed for client-side transitions
    key: 'newsfeed',
  },
  {
    text: 'Explore',
    tabText: 'Explore',
    href: '/home/explore',
    as: '/explore',
    key: 'explore',
  },
  {
    text: 'Saved posts',
    tabText: 'Saved',
    href: '/home/followed',
    as: '/followed',
    key: 'followed',
  },
];

const Nav = styled.nav<{ $isNewsfeed?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colorGreyLight};
  @media (${({ theme }) => theme.underScreenSmall}) {
    border: none;
    box-shadow: ${({ theme }) => theme.shadowLight};
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    @media (${({ theme }) => theme.underScreenSmall}) {
      display: flex;
      padding: 0 8px;
      border-top: 1px solid ${({ theme }) => theme.colorGreyLight};
      ${props => props.$isNewsfeed && 'border: none;'}
    }
  }
`;

const Item = styled.li`
  border-top: 1px solid ${({ theme }) => theme.colorGreyLight};
  a {
    display: block;
    padding: 8px 10px;
    border-left: 4px solid transparent;
    color: ${({ theme }) => theme.colorBlack};
    font-size: ${({ theme }) => theme.fontSizeS};
    line-height: ${({ theme }) => theme.fontLineHeightSWide};
    :hover {
      border-color: ${({ theme }) => theme.colorGreyLight};
    }
    &.isActive {
      border-color: ${({ theme }) => theme.colorGreen};
      font-weight: ${({ theme }) => theme.fontWeightBold};
    }
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    border-top: none;
    a {
      border-left: none;
      border-bottom: 4px solid transparent;
      padding: 6px 0;
      margin: 0 8px;
    }
  }
`;

const DesktopText = styled.span`
  @media (${({ theme }) => theme.underScreenSmall}) {
    display: none;
  }
`;

const MobileText = styled.span`
  @media (${({ theme }) => theme.overScreenSmall}) {
    display: none;
  }
`;

const UserCard = styled.div<{ $isNewsfeed?: boolean }>`
  display: flex;
  padding: 15px 15px 30px;
  ${props => props.$isNewsfeed && 'padding-bottom: 15px;'}

  @media (${({ theme }) => theme.underScreenSmall}) {
    ${props => props.$isNewsfeed && 'display: none;'}
  }

  img {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    margin-right: 10px;
  }
`;

const User = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
`;

const Name = styled.div`
  font-size: ${({ theme }) => theme.fontSizeM};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeightM};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProfileCompletionPercentage = styled.span`
  ${textLight};
  margin: 10px 0;
`;

const UpdateProfileLink = styled.a<{ isNewsfeed?: boolean }>`
  ${props => props.isNewsfeed && 'display: block;'}
`;
